import { round } from '@sh/lib/calc'

export const sanitizeNan = (x:number) => {
  if (Number.isNaN(x)) {
    return '-'
  }
  return x
}

export const formatPercent = (n:number, precision=0) => numberWithCommas(Number(sanitizeNan(n)), precision) + '%'

// TODO consider currency
const TEMP_CURRENCY = 'JPY'
export const fmtCurrency = (n:number) => {
  if (Number.isNaN(n)) {
    return '-'
  }
  return TEMP_CURRENCY==='JPY'
  ? `￥${numberWithCommas(n, 0)}`
  : `$${numberWithCommas(n, 2)}`
}

export const checkFiniteZero = (n: number) => {
  return isFinite(n) ? n : 0
}

// https://stackoverflow.com/questions/2901102/how-to-format-a-number-with-commas-as-thousands-separators
export const numberWithCommas = (n:number, precision=0) => {
  return round(n, precision).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
