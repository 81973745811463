import { Integration } from '../../enums'
import { round } from '../../lib/calc'
import { checkFiniteZero } from '../../../client/src/lib/method/formatNumber'

export class AdData {
  provider: Integration
  start: number
  end: number
  impressions = 0
  clicks = 0   // outbound clicks
  spend = 0
  conversions = 0
  conversionValue = 0

  description?: string

  constructor(
    provider: Integration,
    start:    number,
    end:      number,
    description?: string,
    d?: AdData,
  ) {
    if (d) {
      Object.assign(this, d)
    }

    this.provider = provider
    this.start = start
    this.end = end
    if (description) this.description = description
  } 

  addStats = (impressions:number, clicks:number) => {
    this.impressions += impressions
    this.clicks += clicks
  }
  addSpend = (n:number, precision=2) => {
    this.spend += round(n, precision)
  }
  addConversions = (conversions:number, conversionValue:number, precision=2) => {
    this.conversions += round(conversions, precision)
    this.conversionValue += round(conversionValue, precision)
  }

  ctr = (precision=2) => checkFiniteZero(round(this.clicks/this.impressions, precision))
  cpc = (precision=2) => checkFiniteZero(round(this.spend / this.clicks, precision))
  cpm = (precision=2) => checkFiniteZero(round(this.spend / (this.impressions/1000), precision))
  conversionRate = (precision=2) => checkFiniteZero(round(this.conversions / this.clicks, precision))
  aov = (precision=2) => checkFiniteZero(round(this.conversionValue / this.conversions, precision))
}
