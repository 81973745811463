import { ClientApplication } from '@shopify/app-bridge'
import { AdData } from '@sh/models/classes'
import { AdDataResponse } from '@sh/models/interfaces'
import { Integration } from '@sh/enums'
import { GetAdCampaignArgs } from '@sh/models/types/GraphQLArgs'
import { doPost } from './request'

export const getCampaigns = async(
  args: GetAdCampaignArgs,
  app:  ClientApplication<any>,
):Promise<AdData[]>=> {
  const gql = {
    query: `
    query getFbCampaignData(
      $accountID: String!
      $totals: Boolean
      $from: Int!
      $to: Int!
    ) {
      getFbCampaignData(
        accountID: $accountID
        totals: $totals
        from: $from
        to: $to
      ) {
        campaign
        impressions
        clicks
        spend
        conversions
        conversionValue
      }
    }`,
    variables: {
      ...args,
    },
  }
  const res = await doPost(gql, app)

  const error = res?.errors?.length
    ? res.errors[0].message
    : ''
  if (error) {
    throw new Error(error)
  }

  const adData:AdDataResponse[] = res.data.getFbCampaignData
  return adData
    .filter(d => d.spend>0)
    .map(d => {
      const campaignData = new AdData(Integration.FACEBOOK, args.from, args.to, d.campaign)
      campaignData.impressions = d.impressions
      campaignData.clicks = d.clicks
      campaignData.spend = d.spend
      campaignData.conversions = d.conversions
      campaignData.conversionValue = d.conversionValue
      return campaignData
    })
}
