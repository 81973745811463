// TODO: move these to commons when datetime lib is merged

export const UNIX_SECS = Math.round(new Date().getTime() / 1000)
export const gUnixSec = () => Math.round(new Date().getTime() / 1000)

/**
 * @param d yyyy-mm-dd
 * @returns yyyymmdd
 */
 export const formatDashedDateToNoDash = (d:string):string => {
  const regex = new RegExp(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/)
  if (!regex.test(d)) {
    throw new Error('The date format needs to be yyyy-mm-dd')
  }
  return d.split('-').join('')
}

/**
 * @param d 1642463790 | 1642464671668
 * @param opt  optional. you can add - or /
 * @returns yyyymmdd (default) with option returns yyyy-mm-dd or yyyy/mm/dd
 */
export const formatDateFromNumber = (d:number, opt?: '/' | '-'):string => {
  // date unixsec 1642463790 1642463790000 1642464671668
  let dt = new Date(d * 1000)
  if (Math.log10(d) > 12) {
     dt = new Date(d)
  }
  const year = dt.getUTCFullYear()
  const month =(dt.getUTCMonth() + 1) < 10 ? `0${dt.getUTCMonth() + 1}` : dt.getUTCMonth() + 1
  const date = (dt.getUTCDate()) < 10 ? `0${dt.getUTCDate()}` : dt.getUTCDate()
  if (opt) {
    return `${year}${opt}${month}${opt}${date}`
  }
  return [year, month, date].join(opt || '')
}

/** converts date to local time and then date as YYYYMMDD */
export const dtToIntLocal = (dt: Date) => Number(`${dt.getFullYear()}${('0' + (dt.getMonth()+1)).slice(-2)}${('0' + dt.getDate()).slice(-2)}`)

/** converts int like 20211231 to a js Date object at 00:00:000z */
export const intToDt = (n:number):Date => {
  const s = n.toString()
  return new Date(Date.UTC(Number(s.substring(0,4)), Number(s.substring(4,6))-1, Number(s.substring(6,8)), 0, 0, 0))
}

/**
 * ex) 20211231 + 1 = 20220101
 */
export const addDaysInt = (n:number, days:number):number => {
  const unixTime = intToDt(n).getTime() + (days*1000*60*60*24)
  return dtToIntLocal(new Date(unixTime))
}

/** get current dates */

export const gToday = () => dtToIntLocal(new Date(new Date().toUTCString().substring(0,16)))

export const gThisWeek = (current=gToday()):{start:number, end:number} => {
  return {
    start: addDaysInt(current, -6),
    end: current,
  }
}
export const gLastWeek = (current=gToday()):{start:number, end:number} => {
  const end = addDaysInt(current, -7)
  return {
    start: addDaysInt(end, -6),
    end,
  }
}

export const gThisMonth = (current=gToday()):{start:number, end:number} => {
  return {
    start: Number(`${current.toString().substring(0,6)}01`),
    end: current,
  }
}
export const gLastMonth = (current=gToday()):{start:number, end:number} => {
  const curMonth = gThisMonth(current)
  const end = addDaysInt(curMonth.start, -1)
  return {
    start: Number(`${end.toString().substring(0,6)}01`),
    end,
  }
}

export const gThisYear = (current=gToday()):{start:number, end:number} => {
  return {
    start: Number(`${current.toString().substring(0,4)}0101`),
    end: current,
  }
}
export const gLastYear = (current=gToday()):{start:number, end:number} => {
  const lastYYYY = Number(current.toString().substring(0,4))
  return {
    start: Number(`${lastYYYY}0101`),
    end: Number(`${lastYYYY}1231`),
  }
}

  /**
   * @param dt 'Tue Feb 01 2022 00:00:00 GMT+0900 (Japan Standard Time)',
   * '2022-02-01', 2022-02-01T00:00:00, 1643673600000 etc
   * @returns number 20220201
   */
  export const convertUTCToLocalWithoutDash = (dt:Date):number => {
    const date = new Date(dt)
    const year = date.getFullYear().toString()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    return parseInt([year,month,day].join(''))
  }