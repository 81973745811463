import { gUnixSec } from '../../lib/datetime'
import { DataProvider, DataProviderItem } from './DataProvider'
import { Buffer } from 'buffer'
import { ShopStatus } from '../../enums'

export interface StoreEvent {
  type: string
  description: string
  ts: number
}

// dynamodb store item
export interface StoreItem {
  id: string
  name: string
  status: ShopStatus
  integrations: DataProviderItem[]
  emails: string[]
  url: string

  // these properties are part of a shopify app object (front-end)
  host?: string
  origin?: string

  // properties come from shopify store data
  contactEmail?: string[]
  currencyCode?: string
  timezoneAbbreviation?: string
  timezoneOffset?: string
  token?: {
    access_token: string
    scope: string
  }

  events: StoreEvent[]
  errs?: any
  webhooks?: []
  created: number
  updated: number
}

export class Store implements StoreItem {
  id: string
  name = ''
  status = ShopStatus.CREATED
  integrations:DataProvider[] = []
  emails:string[] = []
  url = ''

  host?: string
  origin?: string

  contactEmail?: string[]
  currencyCode?: string
  timezoneAbbreviation?: string
  timezoneOffset?: string
  token?: {
    access_token: string
    scope: string
  }

  events: StoreEvent[]
  errs?: any
  webhooks?: []
  created: number
  updated: number

  // paymentsEvents: SubscriptionEvent[],

  constructor(item?:StoreItem) {
    if (item) {
      Object.assign(this, item)
      if (item.integrations?.length) {
        this.integrations = item.integrations.map(t => new DataProvider(t))
      } else {
        this.integrations = []
      }
    }
    if (!this.created) this.created = gUnixSec()
    if (!this.updated) this.updated = gUnixSec()
  }

  /** origin looks like ....myshopify.com */
  setShopifyOrigin = (origin:string) => {
    this.host = origin
    this.id = origin.replace('.myshopify.com', '')
  }

  getShopHost = () => {
    return Buffer.from(`${this.host}/admin`).toString('base64')
  }

  /** This method is used to create the record to be stored in the DB */
  getItem = () => {
    const item:StoreItem = {
      id: this.id,
      name: this.name,
      status: this.status,
      integrations: this.integrations.map(dp => dp.getItem()),
      emails: this.emails,
      url: this.url,

      events: this.events,
      created: this.created,
      updated: this.updated,
    }

    if (this.host) item.host = this.host
    if (this.origin) item.origin = this.origin
    if (this.contactEmail) item.contactEmail = this.contactEmail
    if (this.currencyCode) item.currencyCode = this.currencyCode
    if (this.timezoneAbbreviation) item.timezoneAbbreviation = this.timezoneAbbreviation
    if (this.timezoneOffset) item.timezoneOffset = this.timezoneOffset
    if (this.token) item.token = this.token
    if (this.errs) item.errs = this.errs
    if (this.webhooks) item.webhooks = this.webhooks

    return item
  }
}
