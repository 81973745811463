// import { GAVersion } from '../../enums'
import { round } from '../../lib/calc'

export class GAData {
  start: number
  end: number
  sessions = 0
  engagedSessions = 0  // also: 1 - bounce rate
  transactions = 0
  revenue = 0

  description?: string

  constructor(
    start:   number,
    end:     number,
    description?: string,
    d?:           GAData,
  ) {
    if (d) {
      Object.assign(this, d)
    }

    this.start = start
    this.end = end
    if (description) this.description = description
  }

  addSessionData = (sessions:number, engagedSessions:number) => {
    this.sessions += sessions
    this.engagedSessions += engagedSessions
  }
  addTransactionData = (transactions:number, revenue:number) => {
    this.transactions += transactions
    this.revenue += revenue
  }

  engagementRate = (precision=2) => round(this.engagedSessions/this.sessions, precision)
  conversionRate = (precision=2) => round(this.transactions/this.sessions, precision)
  engagedConversionRate = (precision=2) => round(this.transactions/this.engagedSessions, precision)
  aov = (precision=2) => round(this.revenue/this.transactions, precision)

}
