import { ClientApplication } from '@shopify/app-bridge'
import { GetGAChannelArgs } from '@sh/models/types/GraphQLArgs'
import { GAData } from '@sh/models/classes'
import { GADataResponse } from '@sh/models/interfaces'
import { doPost } from './request'

export const getChannels = async(
  args: GetGAChannelArgs,
  app:  ClientApplication<any>,
):Promise<GAData[]>=> {
  const gql = {
    query: `
    query getGAData(
      $accountID: String!
      $totals: Boolean
      $from: Int!
      $to: Int!
    ) {
      getGAData(
        accountID: $accountID
        totals: $totals
        from: $from
        to: $to
      ) {
        channel
        sessions
        engagedSessions
        transactions
        revenue
      }
    }`,
    variables: {
      ...args,
    },
  }
  const res = await doPost(gql, app)

  const error = res?.errors?.length
    ? res.errors[0].message
    : ''
  if (error) {
    throw new Error(error)
  }

  const gaData:GADataResponse[] = res.data.getGAData
  return gaData
    .filter(d => d.sessions>0)
    .map(d => {
      const data = new GAData(args.from, args.to, d.channel)
      data.sessions = d.sessions
      data.engagedSessions = d.engagedSessions
      data.transactions = d.transactions
      data.revenue = d.revenue
      return data
    })
}
