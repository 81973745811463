import { ClientApplication } from '@shopify/app-bridge'
import { EComData } from '@sh/models/classes'
import { GetShopifyOrdersArgs } from '@sh/models/types/GraphQLArgs'
import { doPost } from './request'

type GetChannelRes = {
  total:    EComData
  channels: EComData[]
}

export const getChannels = async(
  args: GetShopifyOrdersArgs,
  app:  ClientApplication<any>,
):Promise<GetChannelRes> => {
  const gql = {
    query: `
    query getEComData(
      $from: Int!
      $to: Int!
    ) {
      getEComData(
        from: $from
        to: $to
      ) {
        orders {
          new
          returning
        }
        revenue {
          new
          returning
        }
        refunds {
          new
          returning
        }
        description
      }
    }`,
    variables: { ...args },
  }
  const res = await doPost(gql, app)
  const total = new EComData(args.from, args.to)
  const channels = res.data.getEComData
    .map((d:EComData) => {
      const ec = new EComData(args.from, args.to, d.description, d)
      total.appendEcom(d)
      return ec
    })
  return {
    total,
    channels,
  }
}
