import { ClientApplication } from '@shopify/app-bridge'
import { doPost } from './request'
import { StoreItem } from '@sh/models/classes/Store'
import { DataProviderItem } from '@sh/models/classes/DataProvider'
import { SavedAuthResponse } from '@sh/models/types/SavedAuth'

export const authorize = async(queryString:string):Promise<string> => {
  const res = await doPost({
    query: `
    mutation Authorize($queryString: String!) {
      authorize(
        queryString: $queryString
      )
    }`,
    variables: { queryString },
  })
  return res.data.authorize
}

// gets store item from db
export const getStore = async(app:ClientApplication<any>):Promise<StoreItem> => {
  const gql = {
    query: `{
      getStore {
        id
        name
        status
        emails
        integrations {
          account
          accountID
          service
          username
          active
          secondaryID
        }
        currencyCode
        url
        timezoneAbbreviation
        timezoneOffset
        emails
      }
    }`,
  }
  const res = await doPost(gql, app)
  return res.data.getStore
}

// gets store item from dynamodb
export const updateEmails = async(
  emails: string[],
  app: ClientApplication<any>,
):Promise<StoreItem> => {
  const gql = {
    query: `
    mutation updateEmails($emails:[String]!) {
      updateEmails(
        emails: $emails
      ) {
        id
        name
        status
        emails
        integrations {
          account
          accountID
          service
          username
          active
          secondaryID
        }
        currencyCode
        url
        timezoneAbbreviation
        timezoneOffset
        emails
      }
    }`,
    variables: { emails },
  }
  const res = await doPost(gql, app)
  return res.data.updateEmails
}

export const updateIntegration = async(
  integration: DataProviderItem,
  app: ClientApplication<any>,
):Promise<StoreItem> => {
  const gql = {
    query: `
    mutation updateIntegration($integration: DataProviderInput!) {
      updateIntegration(
        integration: $integration
      ) {
        id
        name
        status
        emails
        integrations {
          account
          accountID
          service
          username
          active
          secondaryID
        }
        currencyCode
        url
        timezoneAbbreviation
        timezoneOffset
        emails
      }
    }`,
    variables: { integration },
  }

  console.log({ gql })
  const res = await doPost(gql, app)
  return res.data.updateIntegration
}

export const removeIntegration = async(
  integration: DataProviderItem,
  app: ClientApplication<any>,
):Promise<StoreItem> => {
  const gql = {
    query: `
    mutation removeIntegration($integration: DataProviderInput!) {
      removeIntegration(
        integration: $integration
      ) {
        id
        name
        status
        emails
        integrations {
          account
          accountID
          service
          username
          active
          secondaryID
        }
        currencyCode
        url
        timezoneAbbreviation
        timezoneOffset
        emails
      }
    }`,
    variables: { integration },
  }
  const res = await doPost(gql, app)
  return res.data.removeIntegration
}

export const getSavedAuths = async(
  app: ClientApplication<any>,
):Promise<SavedAuthResponse[]> => {
  const gql = {
    query: `{
      getSavedAuths {
        username
        service
        email
        accounts {
          id
          name
          status
          accountName
          masterID
          owner
        }
        error
      }
    }`,
  }
  const res = await doPost(gql, app)
  console.log('get saved auths res', res)
  return res.data.getSavedAuths
}
