export class EComData {
  start: number
  end: number
  orders = {
    new: 0,
    returning: 0,
  }
  revenue = {
    new: 0,
    returning: 0,
  }
  refunds = {
    new: 0,
    returning: 0,
  }
  customers = {
    new: 0,
    returning: 0,
  }
  // cohort = {
  //   active30: 0,
  //   active365: 0,
  //   churned: 0,
  // }

  description?: string

  constructor(
    start: number,
    end:   number,
    description?: string,
    d?:    EComData|OrderSummaryChannelData,
  ) {
    if (d) {
      Object.assign(this, d)
      if (d['channel']) this.description=d['channel']
    }

    this.start = start
    this.end = end
    if (description) this.description = description
  }

  appendEcom = (d:EComData) => {
    this.orders.new += d.orders.new
    this.orders.returning += d.orders.returning
    this.revenue.new += d.revenue.new
    this.revenue.returning += d.revenue.returning
    this.refunds.new += d.refunds.new
    this.refunds.returning += d.refunds.returning
    this.customers.new += d.customers.new
    this.customers.returning += d.customers.returning
  }

  allOrders = () => this.orders.new + this.orders.returning
  allRevenue = () => this.revenue.new + this.revenue.returning
  allRefunds = () => this.refunds.new + this.refunds.returning
  allCustomers = () => this.customers.new + this.customers.returning

  customerReturningRate = () => this.customers.returning/this.allCustomers()
}

/** copied from 1f8-central/package/src/orders/orderSummary.ts */
type OrderSummaryChannelData = {
  channel: string
  orders: {
    new: number
    returning: number
  }
  revenue: {
    new: number
    returning: number
  }
  refunds: {
    new: number
    returning: number
  }
  customers: {
    new: number
    returning: number
  }
}
