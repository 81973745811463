import { ClientApplication } from '@shopify/app-bridge'
import { AdData } from '@sh/models/classes'
import { AdDataResponse } from '@sh/models/interfaces'
import { Integration } from '@sh/enums'
import { GetAdCampaignArgs } from '@sh/models/types/GraphQLArgs'
import { sleep } from '@sh/lib/etc'
import { doPost } from './request'

export const getCampaigns = async(
  args: GetAdCampaignArgs,
  app:  ClientApplication<any>,
):Promise<AdData[]>=> {
  let gettingResponse = true
  let tries = 0
  let data: AdData[]
  let error:string
  while (gettingResponse && !data) {
    const response = await getYJPResponse(args, app)
    error = response?.errors?.length
      ? response.errors[0].message
      : ''
    if (error?.startsWith('Job Requested.')) {
      tries++
      error = undefined
      if (tries < 5) {
        console.log('waiting for YJP job. %s tries', tries)
        await sleep(1500)
      } else {
        gettingResponse = false
      }
    }
    if (response?.data?.getYJPCampaignData) {
      const adData:AdDataResponse[] = response.data.getYJPCampaignData
      data = adData
        .filter(d => d.spend>0)
        .map(d => {
          const campaignData = new AdData(Integration.YJP, args.from, args.to, d.campaign)
          campaignData.impressions = d.impressions
          campaignData.clicks = d.clicks
          campaignData.spend = d.spend
          campaignData.conversions = d.conversions
          campaignData.conversionValue = d.conversionValue
          return campaignData
        })
    }
    if (error) {
      throw new Error(error)
    }
    gettingResponse = false
  }

  return data
}

const getYJPResponse = (
  args: GetAdCampaignArgs,
  app:  ClientApplication<any>,
) => {
  const gql = {
    query: `
    query getYJPCampaignData(
      $accountID: String!
      $totals: Boolean
      $from: Int!
      $to: Int!
    ) {
      getYJPCampaignData(
        shop: $shop
        accountID: $accountID
        totals: $totals
        from: $from
        to: $to
      ) {
        campaign
        impressions
        clicks
        spend
        conversions
        conversionValue
        }
    }`,
    variables: {
      ...args,
    },
  }
  return doPost(gql, app)
}
