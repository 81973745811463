import { API } from 'aws-amplify'
import AmplifyConfig from '../amplify'
import { ClientApplication } from '@shopify/app-bridge'
import { getSessionToken } from '@shopify/app-bridge-utils'

const apiName = AmplifyConfig.aws_cloud_logic_custom[0].name
const graphqlPath = '/graphql'

type GqlArgs = {
  query:string,
  variables?:any,
}

type GqlRes = {
  data: Record<string, any>
  errors: {
    message: string
    extensions: {
      code: string
      exception: any
    }
  }[]
}

export const doPost = async (gql:GqlArgs, app?:ClientApplication<any>):Promise<GqlRes> => {
  const request:any = {
    body: gql,
  }
  if (app) {
    const sessionToken = await getSessionToken(app)
    request.headers = {
      Authorization: `${sessionToken || null}`,
    }
    console.log('testing request headers', request.headers)
  }
  return API.post(
    apiName,
    graphqlPath,
    request,
  )
}
