import { Integration, IntegrationStatus } from '../../enums'
import { SavedAuthResponse } from '../types/SavedAuth'

// dynamodb item
export type DataProviderItem = {
  account: string
  service: Integration
  username: string
  accountID: string
  active?: boolean
  secondaryID?: string
  error?: string
  errorDetail?: any
}

/** The connected integration to each store */
export class DataProvider {
  account: string
  service: Integration
  username: string
  accountID: string
  active?: boolean
  secondaryID?: string
  error?: string
  errorDetail?: any

  index = 0
  auth?:SavedAuthResponse

  getKey = ():string => `${this.service}|${this.index}`

  getStatus = ():IntegrationStatus => {
    if (!this.username) return IntegrationStatus.NOT_CONNECTED
    if (!this.accountID) return IntegrationStatus.INCOMPLETE
    return IntegrationStatus.CONNECTED
  }
  
  getItem = ():DataProviderItem => {
    const item:DataProviderItem = {
      account: this.account,
      service: this.service,
      username: this.username,
      accountID: this.accountID,
    }
    if (this.active) item.active = this.active
    if (this.secondaryID) item.secondaryID = this.secondaryID
    if (this.error) item.error = this.error
    if (this.errorDetail) item.errorDetail = this.errorDetail
    return item
  }

  setAuth = (savedAuths:SavedAuthResponse[]) => {
    if (!savedAuths?.length) return
    this.auth = savedAuths.find(a => !!a && a.service===this.service)
    if (this.auth && !this.username) this.username = this.auth.username
  }

  constructor(o:DataProviderItem) {
    Object.assign(this, o)
  }
}
